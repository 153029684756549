/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { CSVLink } from "react-csv";
import Search from "../components/assets/flowbite_search-outline (1).svg";
import { Triangle } from "react-loader-spinner";
import Chevron from "../components/assets/ep_arrow-up-bold.svg";
import UsersTable from "../components/Tables/UsersTable";
import axiosInstance from "../axios";
import { Dialog, DialogPanel } from "@headlessui/react";
import convertDate from "../utils/ConvertDate";
import { toast } from "react-toastify";

function Users() {
  const [data, setData] = useState([]);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [newUrl, setNewUrl] = useState(null);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filterBy, setFilterBy] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedUserAccounts, setSelectedUserAccounts] = useState();
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);
  const [hideAccounts, setHideAccounts] = useState(true);
  const [banLoading, setBanLoading] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  // const [results, setResults] = useState([]);
  const CSVdata = data;
  const CSVdataModal = selectedUser ? [selectedUser] : [];
  const [selectedUserEdit, setSelectedUserEdit] = useState({
    firstname: "",
    lastname: "",
    bank_name: "",
    account_number: "",
    paypal_address: "",
  });
  const [areYouSure, setAreYouSure] = useState(false);
  // console.log(nextUrl.split("=")[1]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 500); // 300ms debounce time

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    setLoading(true);
    if (debouncedTerm.length > 0) {
      const fetchResults = async () => {
        try {
          const response = await axiosInstance.get(
            `/dashboard/users-list/${debouncedTerm}/`
          );
          // console.log(response.data);
          setNextUrl(response.data.next);
          setPrevUrl(response.data.previous);
          setTotal(response.data.count);
          setData(response.data.results);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching search results", error);
        }
      };

      fetchResults();
    } else {
      axiosInstance
        .get(`${newUrl || "dashboard/users-list"}`)
        .then((res) => {
          console.log(res);
          setLoading(false);
          setNextUrl(res.data.next);
          setPrevUrl(res.data.previous);
          setTotal(res.data.count);
          setData(res.data.results);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [newUrl, debouncedTerm, refresh]);

  useEffect(() => {
    if (selectedUser) {
      setSelectedUserEdit({
        firstname: selectedUser.user.firstname,
        lastname: selectedUser.user.lastname,
      });
      axiosInstance
        .get(`/dashboard/user-account-list/${selectedUser?.user?.email}/`)
        .then((res) => {
          // console.log(res);
          setLoading(false);
          setSelectedUserAccounts([...res.data.accounts, ...res.data.scas]);
          // setNextUrl(res.data.next);
          // setPrevUrl(res.data.previous);
          // setTotal(res.data.count);
          // setData(res.data.results);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedUser]);

  function deactivateUser() {
    setBanLoading(true);
    toast.info("Please Wait...", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    axiosInstance
      .get(`/dashboard/ban-user/${selectedUser.user.id}/`)
      .then((res) => {
        // console.log(res);
        setBanLoading(false);
        setRefresh(!refresh);
        setOpen(false);
        toast.success(res.data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // setSelectedUserAccounts([...res.data.accounts, ...res.data.scas]);
        // setNextUrl(res.data.next);
        // setPrevUrl(res.data.previous);
        // setTotal(res.data.count);
        // setData(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function clearKyc() {
    setClearLoading(true);
    setAreYouSure(false);
    toast.info("Please Wait...", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    axiosInstance
      .get(`/dashboard/clear-user-profile-img/${selectedUser.user.id}/`)
      .then((res) => {
        // console.log(res);
        setClearLoading(false);
        setRefresh(!refresh);
        setOpen(false);
        toast.success(res.data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // setSelectedUserAccounts([...res.data.accounts, ...res.data.scas]);
        // setNextUrl(res.data.next);
        // setPrevUrl(res.data.previous);
        // setTotal(res.data.count);
        // setData(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function updateUser() {
    setBanLoading(true);
    toast.info("Please Wait...", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    axiosInstance
      .put(
        `/dashboard/update-user-profile/${selectedUser.user.id}/`,
        selectedUserEdit
      )
      .then((res) => {
        console.log(res);
        setBanLoading(false);
        setRefresh(!refresh);
        setOpenEdit(false);
        toast.success(res.data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // setSelectedUserAccounts([...res.data.accounts, ...res.data.scas]);
        // setNextUrl(res.data.next);
        // setPrevUrl(res.data.previous);
        // setTotal(res.data.count);
        // setData(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  console.log(selectedUserAccounts)

  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <div className="w-[100%] lg:w-[82%]">
        <Topbar />
        <div className="lg:p-[32px] p-[16px] h-[88vh] overflow-y-scroll">
          <div className="border border-[#E4E4E4] rounded-[8px] py-[24px] hidden lg:block">
            <div className="flex justify-between px-[16px]">
              <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                Users List
              </h1>
              <div className="flex items-center gap-10 text-[0.875rem]">
                <CSVLink data={CSVdata} filename={"users_data.csv"}>
                  <button className="text-[#3EB211] font-poppins font-medium">
                    Export CSV
                  </button>
                </CSVLink>
                <div className="flex items-center border-b border-b-[#1D1D1D] px-2 pb-2">
                  <img src={Search} alt="" />
                  <input
                    onChange={(e) => setSearchTerm(e.target.value)}
                    type="text"
                    placeholder="Search"
                    className="px-3 outline-none font-poppins"
                  />
                </div>
                {/* <button className="border border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 px-3 outline-none rounded-[8px] gap-[8px]">
                  Filter
                  <img src={Chevron} alt="" />
                </button> */}
                <div className="flex items-center gap-5 font-poppins font-medium">
                  {prevUrl && (
                    <button
                      onClick={() => {
                        setNewUrl(prevUrl);
                      }}
                      className="bg-white text-[#3EB211] border border-[#3EB211] py-2 px-4 rounded-lg"
                    >
                      Prev
                    </button>
                  )}
                  {nextUrl && (
                    <div>
                      <p>
                        Page {nextUrl?.split("=")[1] - 1} of{" "}
                        {Math.round(total / 50)}
                      </p>
                    </div>
                  )}
                  {nextUrl && (
                    <button
                      onClick={() => {
                        setCount(count + 1);
                        setNewUrl(nextUrl);
                      }}
                      className="bg-[#3EB211] border border-[#3EB211] text-white rounded-lg py-2 px-4"
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            </div>
            {loading ? (
              <div className="w-[100%] h-[50vh] flex items-center justify-center">
                <Triangle
                  visible={true}
                  height="80"
                  width="80"
                  color="#3EB211"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <UsersTable
                data={data}
                setSelectedUser={setSelectedUser}
                setOpen={setOpen}
              />
            )}
          </div>

          <div className="lg:hidden">
            <div className="flex items-center justify-between">
              <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                Users List
              </h1>

              <CSVLink data={CSVdata} filename={"users_data.csv"}>
                <button className="text-[#3EB211] font-poppins font-medium">
                  Export CSV
                </button>
              </CSVLink>
            </div>
            <div className="flex flex-col items-center gap-10 text-[0.875rem] mt-[24px]">
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center w-[100%] border-b border-b-[#1D1D1D] px-2 pb-2">
                  <img src={Search} alt="" />
                  <input
                    onChange={(e) => setSearchTerm(e.target.value)}
                    type="text"
                    placeholder="Search"
                    className="px-3 outline-none font-poppins"
                  />
                </div>
                {/* <button className="border border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 px-3 outline-none rounded-[8px] gap-[8px]">
                  Filter
                  <img src={Chevron} alt="" />
                </button> */}
              </div>
              <div className="flex items-center gap-5 font-poppins font-medium">
                {prevUrl && (
                  <button
                    onClick={() => {
                      setNewUrl(prevUrl);
                    }}
                    className="bg-white text-[#3EB211] border border-[#3EB211] py-2 px-4 rounded-lg"
                  >
                    Prev
                  </button>
                )}
                {nextUrl && (
                  <div>
                    <p>
                      Page {nextUrl?.split("=")[1] - 1} of{" "}
                      {Math.round(total / 50)}
                    </p>
                  </div>
                )}
                {nextUrl && (
                  <button
                    onClick={() => {
                      setCount(count + 1);
                      setNewUrl(nextUrl);
                    }}
                    className="bg-[#3EB211] border border-[#3EB211] text-white rounded-lg py-2 px-4"
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
            {loading ? (
              <div className="w-[100%] h-[50vh] flex items-center justify-center">
                <Triangle
                  visible={true}
                  height="80"
                  width="80"
                  color="#3EB211"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <UsersTable
                data={data}
                setSelectedUser={setSelectedUser}
                setOpen={setOpen}
              />
            )}
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={() => {
          setHideAccounts(true);
          setSelectedUser();
          setOpen(false);
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex w-screen items-center bg-black/50 justify-center p-4">
          <DialogPanel className="rounded-lg font-poppins space-y-4 border bg-white p-6 w-[600px] lg:max-h-[700px] max-h-[600px] overflow-y-scroll">
            <div className="flex items-center justify-between">
              <CSVLink data={CSVdataModal} filename={"users_data.csv"}>
                <button className="text-[#3EB211] font-poppins font-medium">
                  Export CSV
                </button>
              </CSVLink>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                className="cursor-pointer"
                onClick={() => {
                  setSelectedUser();
                  setOpen(false);
                  setHideAccounts(true);
                }}
              >
                <path
                  d="M12.7001 3.97283C12.6385 3.91103 12.5652 3.862 12.4846 3.82854C12.4039 3.79509 12.3175 3.77787 12.2301 3.77787C12.1428 3.77787 12.0564 3.79509 11.9757 3.82854C11.8951 3.862 11.8218 3.91103 11.7601 3.97283L8.50015 7.22616L5.24015 3.96616C5.17843 3.90444 5.10515 3.85548 5.02451 3.82208C4.94387 3.78868 4.85744 3.77148 4.77015 3.77148C4.68286 3.77148 4.59643 3.78868 4.51579 3.82208C4.43514 3.85548 4.36187 3.90444 4.30015 3.96616C4.23843 4.02789 4.18947 4.10116 4.15606 4.1818C4.12266 4.26245 4.10547 4.34888 4.10547 4.43616C4.10547 4.52345 4.12266 4.60988 4.15606 4.69053C4.18947 4.77117 4.23843 4.84444 4.30015 4.90616L7.56015 8.16616L4.30015 11.4262C4.23843 11.4879 4.18947 11.5612 4.15606 11.6418C4.12266 11.7224 4.10547 11.8089 4.10547 11.8962C4.10547 11.9835 4.12266 12.0699 4.15606 12.1505C4.18947 12.2312 4.23843 12.3044 4.30015 12.3662C4.36187 12.4279 4.43514 12.4768 4.51579 12.5102C4.59643 12.5437 4.68286 12.5608 4.77015 12.5608C4.85744 12.5608 4.94387 12.5437 5.02451 12.5102C5.10515 12.4768 5.17843 12.4279 5.24015 12.3662L8.50015 9.10616L11.7601 12.3662C11.8219 12.4279 11.8951 12.4768 11.9758 12.5102C12.0564 12.5437 12.1429 12.5608 12.2301 12.5608C12.3174 12.5608 12.4039 12.5437 12.4845 12.5102C12.5652 12.4768 12.6384 12.4279 12.7001 12.3662C12.7619 12.3044 12.8108 12.2312 12.8442 12.1505C12.8776 12.0699 12.8948 11.9835 12.8948 11.8962C12.8948 11.8089 12.8776 11.7224 12.8442 11.6418C12.8108 11.5612 12.7619 11.4879 12.7001 11.4262L9.44015 8.16616L12.7001 4.90616C12.9535 4.65283 12.9535 4.22616 12.7001 3.97283Z"
                  fill="#333333"
                />
              </svg>
            </div>
            <div className="flex items-center flex-col justify-center border-b border-b-[#E4E4E4] pb-6">
              <p className="text-center text-[1.125rem] font-medium text-[#333333] mb-2">
                User Details
              </p>
              <p
                className={`${
                  selectedUser?.status === "active"
                    ? "bg-[#72E52C1A] text-[#72E52C]"
                    : "bg-[#B410071A] text-[#B41007]"
                } text-[0.875rem] text-center w-max font-medium rounded-[200px] px-2 py-1 outline-none`}
              >
                {selectedUser?.status === "active" ? "Active" : "Deactivated"}
              </p>
            </div>
            <div className="flex items-center rounded-lg flex-col justify-center border border-[#E4E4E4] p-6 space-y-6">
              <div className="w-full space-y-6">
                <div className="flex items-center justify-between flex-wrap gap-6 w-[100%]">
                  <div>
                    <p className="text-[0.75rem] text-[#62646A99] mb-1">
                      USERNAME
                    </p>
                    <p className="text-[0.875rem] text-[#333333] font-medium">
                      {" "}
                      {selectedUser?.user.lastname},{" "}
                      {selectedUser?.user.firstname}
                    </p>
                  </div>
                  <div>
                    <p className="text-[0.75rem] text-[#62646A99] mb-1">
                      EMAIL ADDRESS
                    </p>
                    <p className="text-[0.875rem] text-[#333333] font-medium">
                      {" "}
                      {selectedUser?.user.email}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between flex-wrap gap-6 w-[100%]">
                  <div>
                    <p className="text-[0.75rem] text-[#62646A99] mb-1">
                      PHONE NO.
                    </p>
                    <p className="text-[0.875rem] text-[#333333] font-medium">
                      +{selectedUser?.user.phone}
                    </p>
                  </div>
                  <div>
                    <p className="text-[0.75rem] text-[#62646A99] mb-1">
                      COUNTRY
                    </p>
                    <p className="text-[0.875rem] text-[#333333] font-medium">
                      {" "}
                      {selectedUser?.country || "Not provided"}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between w-[100%]">
                  <div>
                    <p className="text-[0.75rem] text-[#62646A99] mb-1">
                      NO. OF A/Cs
                    </p>
                    <p className="text-[0.875rem] text-[#333333] font-medium">
                      {selectedUser?.num_of_accounts}
                    </p>
                  </div>
                  <div>
                    <p className="text-[0.75rem] text-[#62646A99] mb-1">
                      REGISTRATION DATE
                    </p>
                    <p className="text-[0.875rem] text-[#333333] font-medium">
                      {convertDate(selectedUser?.date_joined)}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between w-[100%]">
                  <div>
                    <p className="text-[0.75rem] text-[#62646A99] mb-1">
                      BANK NAME
                    </p>
                    <p className="text-[0.875rem] text-[#333333] font-medium">
                      {selectedUser?.bank_name || "Not Provided"}
                    </p>
                  </div>
                  <div>
                    <p className="text-[0.75rem] text-[#62646A99] mb-1">
                      ACCOUNT NUMBER
                    </p>
                    <p className="text-[0.875rem] text-[#333333] font-medium">
                      {selectedUser?.account_number || "Not Provided"}
                    </p>
                  </div>
                </div>
              </div>

              <h1
                onClick={() => setHideAccounts(!hideAccounts)}
                className="flex items-center text-center text-[0.875rem] cursor-pointer font-semibold gap-3"
              >
                {hideAccounts ? "Show Accounts" : "Hide Accounts"}{" "}
                <img
                  className={`${hideAccounts && "rotate-180"}`}
                  src={Chevron}
                  alt=""
                />
              </h1>
              {selectedUserAccounts?.length > 0 &&
                !hideAccounts &&
                selectedUserAccounts.map((acc) => (
                  <div
                    key={acc._id}
                    className="flex items-center w-full rounded-lg flex-col justify-center border border-[#E4E4E4] p-6 space-y-6"
                  >
                    <div className="flex items-center justify-between flex-wrap gap-6 w-[100%]">
                      <div>
                        <p className="text-[0.75rem] text-[#62646A99] mb-1">
                          USER
                        </p>
                        <p className="text-[0.875rem] text-[#333333] font-medium">
                          {" "}
                          {selectedUser.user.firstname}
                          <p className="text-[#62646A99] text-[0.75rem] font-medium">
                            {selectedUser.user.email}
                          </p>
                        </p>
                      </div>
                      <div>
                        <p className="text-[0.75rem] text-[#62646A99] mb-1">
                          ACCOUNT TYPE
                        </p>
                        <p className="text-[0.875rem] text-[#333333] font-medium">
                          {" "}
                          {acc?.typeofaccount || acc?.acc?.typeofaccount}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center justify-between flex-wrap gap-6 w-[100%]">
                      <div>
                        <p className="text-[0.75rem] text-[#62646A99] mb-1">
                          A/C NO.
                        </p>
                        <p className="text-[0.875rem] text-[#333333] font-medium">
                          {acc?.number || acc?.acc?.number}
                        </p>
                      </div>
                      <div>
                        <p className="text-[0.75rem] text-[#62646A99] mb-1">
                          DICOUNT CODE
                        </p>
                        <p className="text-[0.875rem] text-[#333333] font-medium">
                          {" "}
                          {acc?.discount_code || "Not provided"}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center justify-between w-[100%]">
                      <div>
                        <p className="text-[0.75rem] text-[#62646A99] mb-1">
                          BLOWN
                        </p>
                        <p
                          className={`${
                            !acc.blown
                              ? "bg-[#72E52C1A] text-[#72E52C]"
                              : "bg-[#B410071A] text-[#B41007]"
                          } text-[0.875rem] text-center w-max font-medium rounded-[200px] px-2 py-1 outline-none`}
                        >
                          {acc.blown ? "Yes" : "No"}
                        </p>
                      </div>
                      <div>
                        <p className="text-[0.75rem] text-[#62646A99] mb-1">
                          STATUS
                        </p>
                        <p
                          className={`${
                            !acc.inactive
                              ? "bg-[#72E52C1A] text-[#72E52C]"
                              : "bg-[#B410071A] text-[#B41007]"
                          } text-[0.875rem] text-center w-max font-medium rounded-[200px] px-2 py-1 outline-none`}
                        >
                          {!acc.inactive ? "Active" : "Inactive"}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            <button
              onClick={() => {
                setOpen(false);
                setOpenEdit(true);
              }}
              className="bg-[#3EB211] border border-[#3EB211] text-white rounded-md py-3 w-[100%]"
            >
              Edit
            </button>
            <button
              onClick={() => {
                // clearKyc();
                setAreYouSure(true);
              }}
              className="bg-white border border-[#3EB211] text-[#3EB211] rounded-md py-3 w-[100%]"
            >
              {clearLoading ? "Please wait..." : "Clear KYC Image"}
            </button>
            <button
              onClick={deactivateUser}
              className={`${
                selectedUser?.status === "banned"
                  ? "border border-[#3EB211] text-[#3EB211] bg-white"
                  : "bg-[#B41007] border border-[#B41007] text-white"
              } rounded-md py-3 w-[100%]`}
            >
              {selectedUser?.status === "banned"
                ? "Activate"
                : banLoading
                ? "Please wait..."
                : "Deactivate"}
            </button>
          </DialogPanel>
        </div>
      </Dialog>
      <Dialog
        open={openEdit}
        onClose={() => {
          setHideAccounts(true);
          setSelectedUser();
          setOpenEdit(false);
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex w-screen items-center bg-black/50 justify-center p-4">
          <DialogPanel className="rounded-lg font-poppins space-y-6 border bg-white p-6 w-[600px] max-h-[700px]">
            <div className="flex items-center justify-end">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                className="cursor-pointer"
                onClick={() => {
                  setSelectedUser();
                  setOpenEdit(false);
                  setHideAccounts(true);
                }}
              >
                <path
                  d="M12.7001 3.97283C12.6385 3.91103 12.5652 3.862 12.4846 3.82854C12.4039 3.79509 12.3175 3.77787 12.2301 3.77787C12.1428 3.77787 12.0564 3.79509 11.9757 3.82854C11.8951 3.862 11.8218 3.91103 11.7601 3.97283L8.50015 7.22616L5.24015 3.96616C5.17843 3.90444 5.10515 3.85548 5.02451 3.82208C4.94387 3.78868 4.85744 3.77148 4.77015 3.77148C4.68286 3.77148 4.59643 3.78868 4.51579 3.82208C4.43514 3.85548 4.36187 3.90444 4.30015 3.96616C4.23843 4.02789 4.18947 4.10116 4.15606 4.1818C4.12266 4.26245 4.10547 4.34888 4.10547 4.43616C4.10547 4.52345 4.12266 4.60988 4.15606 4.69053C4.18947 4.77117 4.23843 4.84444 4.30015 4.90616L7.56015 8.16616L4.30015 11.4262C4.23843 11.4879 4.18947 11.5612 4.15606 11.6418C4.12266 11.7224 4.10547 11.8089 4.10547 11.8962C4.10547 11.9835 4.12266 12.0699 4.15606 12.1505C4.18947 12.2312 4.23843 12.3044 4.30015 12.3662C4.36187 12.4279 4.43514 12.4768 4.51579 12.5102C4.59643 12.5437 4.68286 12.5608 4.77015 12.5608C4.85744 12.5608 4.94387 12.5437 5.02451 12.5102C5.10515 12.4768 5.17843 12.4279 5.24015 12.3662L8.50015 9.10616L11.7601 12.3662C11.8219 12.4279 11.8951 12.4768 11.9758 12.5102C12.0564 12.5437 12.1429 12.5608 12.2301 12.5608C12.3174 12.5608 12.4039 12.5437 12.4845 12.5102C12.5652 12.4768 12.6384 12.4279 12.7001 12.3662C12.7619 12.3044 12.8108 12.2312 12.8442 12.1505C12.8776 12.0699 12.8948 11.9835 12.8948 11.8962C12.8948 11.8089 12.8776 11.7224 12.8442 11.6418C12.8108 11.5612 12.7619 11.4879 12.7001 11.4262L9.44015 8.16616L12.7001 4.90616C12.9535 4.65283 12.9535 4.22616 12.7001 3.97283Z"
                  fill="#333333"
                />
              </svg>
            </div>
            <div className="flex items-center flex-col justify-center border-b border-b-[#E4E4E4] pb-6">
              <p className="text-center text-[1.125rem] font-medium text-[#333333] mb-2">
                User Details
              </p>
            </div>

            <div className="space-y-4">
              <div className="flex flex-col">
                <label
                  className="font-poppins mb-2 text-[#62646A99] font-medium"
                  htmlFor=""
                >
                  First Name
                </label>
                <input
                  type="text"
                  value={selectedUserEdit.firstname}
                  onChange={(e) =>
                    setSelectedUserEdit({
                      ...selectedUserEdit,
                      firstname: e.target.value,
                    })
                  }
                  className="border border-[#333333] py-1 px-3 outline-none #1D1D1D rounded"
                />
              </div>

              <div className="flex flex-col">
                <label
                  className="font-poppins mb-2 text-[#62646A99] font-medium"
                  htmlFor=""
                >
                  Last Name
                </label>
                <input
                  type="text"
                  value={selectedUserEdit.lastname}
                  onChange={(e) =>
                    setSelectedUserEdit({
                      ...selectedUserEdit,
                      lastname: e.target.value,
                    })
                  }
                  className="border border-[#333333] py-1 px-3 outline-none #1D1D1D rounded"
                />
              </div>
              <div className="flex flex-col">
                <label
                  className="font-poppins mb-2 text-[#62646A99] font-medium"
                  htmlFor=""
                >
                  Bank Name
                </label>
                <input
                  type="text"
                  value={selectedUserEdit.bank_name}
                  onChange={(e) =>
                    setSelectedUserEdit({
                      ...selectedUserEdit,
                      bank_name: e.target.value,
                    })
                  }
                  className="border border-[#333333] py-1 px-3 outline-none #1D1D1D rounded"
                />
              </div>
              <div className="flex flex-col">
                <label
                  className="font-poppins mb-2 text-[#62646A99] font-medium"
                  htmlFor=""
                >
                  Account Number
                </label>
                <input
                  type="text"
                  value={selectedUserEdit.account_number}
                  onChange={(e) =>
                    setSelectedUserEdit({
                      ...selectedUserEdit,
                      account_number: e.target.value,
                    })
                  }
                  className="border border-[#333333] py-1 px-3 outline-none #1D1D1D rounded"
                />
              </div>
              <div className="flex flex-col">
                <label
                  className="font-poppins mb-2 text-[#62646A99] font-medium"
                  htmlFor=""
                >
                  Paypal Address
                </label>
                <input
                  type="text"
                  value={selectedUserEdit.paypal_address}
                  onChange={(e) =>
                    setSelectedUserEdit({
                      ...selectedUserEdit,
                      paypal_address: e.target.value,
                    })
                  }
                  className="border border-[#333333] py-1 px-3 outline-none #1D1D1D rounded"
                />
              </div>
            </div>

            <button
              onClick={updateUser}
              className="bg-[#3EB211] text-white rounded-md py-3 w-[100%]"
            >
              {banLoading ? "Please Wait..." : "Save"}
            </button>
            <button
              onClick={() => {
                setSelectedUser();
                setOpenEdit(false);
                setHideAccounts(true);
              }}
              className={`${"border border-[#3EB211] text-[#3EB211] bg-white"} rounded-md py-3 w-[100%]`}
            >
              Cancel
            </button>
          </DialogPanel>
        </div>
      </Dialog>
      <Dialog
        open={areYouSure}
        onClose={() => {
          setAreYouSure(false);
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex w-screen items-center bg-black/50 justify-center p-4">
          <DialogPanel className="rounded-lg font-poppins space-y-6 border bg-white p-6 w-[600px] max-h-[700px]">
            <div className="flex items-center justify-end">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                className="cursor-pointer"
                onClick={() => {
                  setAreYouSure(false);
                }}
              >
                <path
                  d="M12.7001 3.97283C12.6385 3.91103 12.5652 3.862 12.4846 3.82854C12.4039 3.79509 12.3175 3.77787 12.2301 3.77787C12.1428 3.77787 12.0564 3.79509 11.9757 3.82854C11.8951 3.862 11.8218 3.91103 11.7601 3.97283L8.50015 7.22616L5.24015 3.96616C5.17843 3.90444 5.10515 3.85548 5.02451 3.82208C4.94387 3.78868 4.85744 3.77148 4.77015 3.77148C4.68286 3.77148 4.59643 3.78868 4.51579 3.82208C4.43514 3.85548 4.36187 3.90444 4.30015 3.96616C4.23843 4.02789 4.18947 4.10116 4.15606 4.1818C4.12266 4.26245 4.10547 4.34888 4.10547 4.43616C4.10547 4.52345 4.12266 4.60988 4.15606 4.69053C4.18947 4.77117 4.23843 4.84444 4.30015 4.90616L7.56015 8.16616L4.30015 11.4262C4.23843 11.4879 4.18947 11.5612 4.15606 11.6418C4.12266 11.7224 4.10547 11.8089 4.10547 11.8962C4.10547 11.9835 4.12266 12.0699 4.15606 12.1505C4.18947 12.2312 4.23843 12.3044 4.30015 12.3662C4.36187 12.4279 4.43514 12.4768 4.51579 12.5102C4.59643 12.5437 4.68286 12.5608 4.77015 12.5608C4.85744 12.5608 4.94387 12.5437 5.02451 12.5102C5.10515 12.4768 5.17843 12.4279 5.24015 12.3662L8.50015 9.10616L11.7601 12.3662C11.8219 12.4279 11.8951 12.4768 11.9758 12.5102C12.0564 12.5437 12.1429 12.5608 12.2301 12.5608C12.3174 12.5608 12.4039 12.5437 12.4845 12.5102C12.5652 12.4768 12.6384 12.4279 12.7001 12.3662C12.7619 12.3044 12.8108 12.2312 12.8442 12.1505C12.8776 12.0699 12.8948 11.9835 12.8948 11.8962C12.8948 11.8089 12.8776 11.7224 12.8442 11.6418C12.8108 11.5612 12.7619 11.4879 12.7001 11.4262L9.44015 8.16616L12.7001 4.90616C12.9535 4.65283 12.9535 4.22616 12.7001 3.97283Z"
                  fill="#333333"
                />
              </svg>
            </div>

            <div className="space-y-4 flex items-center justify-center flex-col">
              <p>Are you sure you want to clear the KYC images of the user?</p>
              <div className="flex items-center gap-5 w-full">
                <button
                  onClick={() => {
                    clearKyc();
                  }}
                  className="bg-[#72E52C] text-white w-[50%]"
                >
                  Yes
                </button>
                <button
                  onClick={() => {
                    // setOpenMenu(false);
                    setSelectedUser();
                    setOpen(false);
                    setAreYouSure(false);
                  }}
                  className="bg-[#B41007] text-white w-[50%]"
                >
                  No
                </button>
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  );
}

export default Users;
